import {Component, Input} from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-upgrade-button',
    templateUrl: './upgrade-button.component.html',
    styleUrls: ['./upgrade-button.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class UpgradeButtonComponent {
  // Inputs
  @Input() showLabel = true;
}
